



































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import FilterGroup from '@/components/filterGroup'
import { AttendanceController, DropDownController } from '@/services/request.service'
import { clearEmptyArray, Debounce, calcSymbol } from '@/utils/utils'
import AddRemark from '@/views/Attendance/StudentAttendance/components/AddRemark.vue'
import EleStudentDetail from '../StudentAttendance/components/StudentDetail.vue'
import PreStudentDetail from './components/StudentDetail.vue'
import TableTotal from '@/components/TableTotal.vue'
import FlexTooltip from '@/components/FlexTooltip.vue'
import moment from 'moment'
import { createPagination, statusList } from '@/constant/constant'
import { calcBg } from '@/utils/utils'
import CountTextarea from '@/components/CountTextarea.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { i18n } from '@/i18n/i18n'
import cloneDeep from 'lodash/cloneDeep'

@Component({
  components: {
    FilterGroup,
    AddRemark,
    EleStudentDetail,
    PreStudentDetail,
    TableTotal,
    FlexTooltip,
    CountTextarea,
    SvgIcon,
  },
})
export default class KindergartenAtten extends Vue {
  private attendanceForm: any = {
    comment: '',
    status: undefined,
  }
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private batchCILoading: boolean = false
  private confirmLoading: boolean = false
  private current: any = moment()
  private detailVis: boolean = false
  private editStudentId: any
  private campusType = '1230'
  private editVis: boolean = false
  private filters: any = {
    date: moment(),
    homeroomId: [],
    houseGroupId: undefined,
  }
  private homerooms: any = []
  private houses: any = []
  private isLate: boolean = false
  private loading: boolean = false
  private interval: any = null
  private statusDisabled: any = false
  private data: any = []
  private startTime: any = moment()
    .hour(9)
    .minute(0)
    .second(0)
    .millisecond(0)
  private visStudent: any = {}
  private calcBg = calcBg
  private dateStart = moment()
  private detailModify = false
  private weekViewer = false
  private windowH: any = 696
  private dynamicHeader: any = []
  private studentCampus = ''
  private detailVisible = false
  private remarkVisible = false
  private record: any = {}

  private moment = moment
  private calcSymbol = calcSymbol

  @Watch('detailVis')
  private onVisChange(val) {
    if (!val && this.detailModify) {
      this.getData()
    }
  }

  private get statusList(): any {
    return this.$store.state.attendanceStatusList.length
      ? this.$store.state.attendanceStatusList
      : statusList
  }

  private get beforeToday(): boolean {
    return this.filters.date.isBefore(moment().startOf('day'))
  }

  private get attendanceDisabled(): any {
    return (
      !this.operationAuths.includes('2007') ||
      !this.data.length ||
      this.filters.date.isAfter(moment().endOf('day'))
    )
  }

  private get ratio(): any {
    return this.$store.state.ratio || 1
  }

  private get columns(): Array<any> {
    let weekCol = [
      {
        key: 'name',
        title: this.$t('attendance.name'),
        ellipsis: true,
        width: 220,
        fixed: 'left',
        scopedSlots: { customRender: 'fixedName' },
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
          }
        },
      },
      {
        dataIndex: 'gender',
        width: 70,
        ellipsis: true,
        fixed: 'left',
        title: this.$t('myClass.student.gender'),
        scopedSlots: { customRender: 'fixedGender' },
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
          }
        },
      },
      {
        dataIndex: 'className',
        width: 80,
        ellipsis: true,
        fixed: 'left',
        title: this.$t('attendance.class'),
        scopedSlots: { customRender: 'commonFix' },
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
          }
        },
      },
      {
        dataIndex: 'studentNum',
        width: 100,
        ellipsis: true,
        fixed: 'left',
        title: this.$t('attendance.studentId'),
        scopedSlots: { customRender: 'commonFix' },
        customCell: (record, index) => {
          return {
            style: { background: '#fff' },
          }
        },
      },
    ]
    if (this.weekViewer) {
      let dynamicCol = this.dynamicHeader.map(item => ({
        dataIndex: item.key,
        key: item.key,
        date: item.date,
        align: 'center',
        title: `${moment(item.date).format('MM.DD')}\n${item.timeSegment}`,
        scopedSlots: { customRender: 'dynamicColumns' },
        customHeaderCell: () => ({ style: { whiteSpace: 'pre-line' } }),
        customCell: (record, index) => {
          return {
            style: { background: calcBg(record[item.key]), cursor: 'pointer' },
            class: 'dynamic-cell',
          }
        },
      }))
      weekCol = weekCol.concat(dynamicCol)
    }
    return this.weekViewer
      ? weekCol
      : [
          {
            key: 'name',
            title: this.$t('attendance.name'),
            ellipsis: true,
            scopedSlots: { customRender: 'name' },
          },
          {
            dataIndex: 'gender',
            width: 130 * this.ratio,
            title: this.$t('myClass.student.gender'),
            scopedSlots: { customRender: 'gender' },
          },
          {
            dataIndex: 'className',
            key: 'class',
            width: 200 * this.ratio,
            ellipsis: true,
            title: this.$t('attendance.class'),
          },
          {
            dataIndex: 'houseName',
            key: 'house',
            width: 200 * this.ratio,
            title: this.$t('attendance.house'),
          },
          {
            dataIndex: 'studentNum',
            key: 'studentId',
            width: 150 * this.ratio,
            title: this.$t('attendance.studentId'),
          },
          {
            key: 'attendance',
            slots: { title: 'attendance' },
            width: 250 * this.ratio,
            scopedSlots: { customRender: 'currentAttendance' },
          },
          {
            key: 'currentRemark',
            width: 120 * this.ratio,
            title: this.$t('attendance.remark'),
            scopedSlots: { customRender: 'currentRemark' },
          },
        ].flat()
  }

  private disabledDate(current): any {
    return (
      current &&
      (current < moment(this.dateStart).startOf('day') ||
        current > moment(this.currentSchoolYear.endTime).endOf('day'))
    )
  }

  private get currentSchoolYear(): any {
    return this.$store.state.currentSchoolYear
  }

  // private get lastDisabled(): any {
  //   return moment(this.date).subtract(1, 'month') <= moment(this.currentSchoolYear.startTime).startOf('day')
  // }

  // private get nextDisabled(): any {
  //   return moment(this.date).add(1, 'month') >= moment(this.currentSchoolYear.endTime).endOf('day')
  // }

  private get afterToday(): boolean {
    return this.filters.date.isAfter(moment().endOf('day'))
  }

  private get isToday(): boolean {
    return !(this.beforeToday || this.filters.date.isAfter(moment().endOf('day')))
  }

  private get locale(): any {
    return this.$store.state.locale
  }

  private get operationAuths(): any {
    return this.$store.state.operationAuths || []
  }

  private get allPunctualDisabled(): any {
    return (
      !this.data.length ||
      this.data[0]?.status === 'weekendHoliday' ||
      this.filters.date.isAfter(moment().endOf('day'))
    )
  }

  // private onSelectChange():void {
  //   this.$refs.selectColor.style.backgroundColor = this.calcBg(this.attendanceForm.status)
  // }

  private allNormal(): void {
    if (!this.filters.date || (!this.filters.homeroomId.length && !this.filters.houseGroupId))
      return
    this.$confirm({
      title: this.$t('tips.allPunctualConfirm') as string,
      onOk: () => {
        this.batchCILoading = true
        AttendanceController.setInTimeBatchKG(
          moment(this.filters.date.endOf('day')).valueOf(),
          this.filters.homeroomId[1] || undefined,
          this.filters.houseGroupId
        )
          .then(res => {
            this.$message.success(this.$tc('tips.clockInSuccess'))
            this.getData()
          })
          .catch(err => console.log(err))
          .finally(() => (this.batchCILoading = false))
      },
      onCancel: () => {
        return
      },
    })
  }

  private clockIn(student): void {
    this.$set(student, 'loading', true)
    AttendanceController.punch(student.studentId)
      .then(res => {
        this.$message.success(this.$tc('tips.clockInSuccess'))
        this.getData()
      })
      .catch(err => console.log(err))
      .finally(() => (student.loading = false))
  }

  private getDateStart(): any {
    AttendanceController.getEditStart()
      .then(res => {
        this.dateStart = moment(res.data)
      })
      .catch(err => console.log(err))
  }

  private closeModal(): void {
    this.attendanceForm = {
      comment: '',
      status: undefined,
    }
    this.editVis = false
  }

  private confirm(): void {
    ;(this.$refs['attendanceForm'] as any).validate(valid => {
      if (valid) {
        this.confirmLoading = true
        const condition = {
          studentId: this.editStudentId,
          date: this.filters.date.valueOf(),
          status: this.attendanceForm.status,
          comment: this.attendanceForm.comment || '',
        }
        AttendanceController.setAttendanceKG(condition)
          .then(res => {
            this.editVis = false
            this.attendanceForm = {
              comment: '',
              status: undefined,
            }
            this.$message.success(this.$tc('tips.saveSuccess'))
            this.getData()
          })
          .catch(err => console.log(err))
          .finally(() => (this.confirmLoading = false))
      } else {
        return false
      }
    })
  }

  private editStatus(student): void {
    this.editStudentId = student.studentId
    this.statusDisabled = !student.editFlag
    this.attendanceForm.comment = student.comment
    this.attendanceForm.status = student.status === 'noRecords' ? undefined : student.status
    this.editVis = true
  }

  private filterData(inputValue, path) {
    return path.some(option => option.value.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
  }

  private getData(page = { current: 1, pageSize: 50 }): void {
    this.data = []
    if (!this.filters.date || (!this.filters.homeroomId?.length && !this.filters.houseGroupId))
      return
    this.loading = true
    AttendanceController.getKGClassAttendance(
      moment(this.filters.date.endOf('day')).valueOf(),
      this.filters.homeroomId?.[1] || undefined,
      this.filters.houseGroupId || undefined
    )
      .then(res => {
        this.data = res.data
      })
      .catch(err => console.log(err))
      .finally(() => (this.loading = false))
  }

  private getWeekData(page = { current: 1, pageSize: this.pagination.defaultPageSize }): void {
    this.data = []
    this.dynamicHeader = []
    if (!this.filters.date || (!this.filters.homeroomId && !this.filters.houseGroupId)) return
    this.loading = true
    AttendanceController.getKGWeeklyAttendances(
      moment(this.filters.date.endOf('day')).valueOf(),
      page.current,
      page.pageSize,
      this.filters.houseId || undefined,
      this.filters.homeroomId?.[1] || undefined
    )
      .then(res => {
        this.dynamicHeader = res.data.weekDays
        this.data = res.data.attendances.items.map(item => ({
          ...item,
          ...item.attendanceStatusMap,
        }))
        this.pagination.total = res.data.attendances.totalItem
      })
      .catch(err => console.log(err))
      .finally(() => (this.loading = false))
  }

  private handleTableChange(pagination): void {
    this.pagination.current = pagination.current
    this.pagination.pageSize = pagination.pageSize
    this.weekViewer ? this.getWeekData(this.pagination) : this.getData(this.pagination)
  }

  private refresh(): void {
    this.weekViewer ? this.getWeekData(this.pagination) : this.getData(this.pagination)
  }

  private setAttendance(key, studentId, comment): void {
    const request = {
      date: moment(this.filters.date).valueOf(),
      studentId,
      comment,
      status: key,
    }
    AttendanceController.setAttendanceKG(request).then(res => {
      let data = cloneDeep(this.data)
      data.forEach(item => {
        if (item.studentId === studentId) {
          item.status = key
        }
      })
      this.data = cloneDeep(data)
    })
  }

  private addRemark(record, filed): void {
    this.record = record
    this.$set(record, 'amOrPm', filed)
    this.remarkVisible = true
  }

  private viewDetail(studentId, studentName, campus, attendanceType): void {
    this.studentCampus = campus
    this.$nextTick(() => {
      this.visStudent.studentId = studentId
      this.visStudent.studentName = studentName
      this.detailVisible = true
      this.detailModify = false
    })
  }

  private getDropDownInfo(): void {
    Promise.all([AttendanceController.getKGClassList(), DropDownController.getHouseGroupList()])
      .then(res => {
        this.homerooms = clearEmptyArray(res[0].data)
        this.houses = res[1].data
        if (this.homerooms.length) {
          if (this.$route.query.homeroomId) {
            this.homerooms.forEach(homeroom => {
              homeroom.subOptions.forEach(option => {
                if (option.key === this.$route.query.homeroomId) {
                  this.campusType = homeroom.extraValue
                  this.filters.homeroomId = [homeroom.key, option.key]
                }
              })
            })
          }
        }
        if (this.houses.length) {
          if (this.$route.query.houseGroupId) {
            this.filters.houseGroupId = Number(this.$route.query.houseGroupId)
          }
        }
        this.$nextTick(() => {
          this.getData()
        })
      })
      .catch(err => console.log(err))
  }

  private judgeIsLate(): void {
    AttendanceController.getServerTime()
      .then(res => {
        this.isLate = res.data.isLate
      })
      .catch(err => console.log(err))
      .finally(() => (this.loading = false))
  }

  private onHomeroomChange(params): void {
    this.campusType = this.homerooms.find(item => item.key === params[0])?.extraValue
    this.weekViewer ? this.getWeekData() : this.getData()
  }

  private showStudentDetail(student): void {
    this.visStudent = student
    this.detailVis = true
    this.detailModify = false
  }

  @Debounce(500)
  private onWindowResize(): void {
    this.windowH = (this.$refs.attendanceViewer as Element)?.getBoundingClientRect().height || 696
  }

  private changeViewer(): void {
    this.weekViewer = !this.weekViewer
    this.weekViewer ? this.getWeekData() : this.getData()
  }

  private mounted(): void {
    this.getDropDownInfo()
    this.getDateStart()
    this.judgeIsLate()
    setTimeout(() => {
      this.windowH = (this.$refs.attendanceViewer as Element)?.getBoundingClientRect().height || 696
    }, 0)
    window.addEventListener('resize', this.onWindowResize)
    this.$once('hook:beforeDestory', () => {})
    this.interval = setInterval(() => {
      this.judgeIsLate()
    }, 5 * 60 * 1000)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('resize', this.onWindowResize)
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = null
      }
    })
  }

  private setWeekAttendance(key, record, colKey): void {
    const day = this.dynamicHeader.find(item => item.key === colKey)
    const studentId = record.studentId
    const request = {
      date: moment(day.date).valueOf(),
      studentId,
      comment: '',
      status: key,
    }
    AttendanceController.setAttendanceKG(request).then(res => {
      this.getWeekData(this.pagination)
    })
  }

  private selectDate(value): void {
    this.weekViewer ? this.getWeekData() : this.getData()
  }
}
