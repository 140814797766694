var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"attendanceViewer",staticClass:"attendance-viewer"},[_c('div',{staticClass:"filter-container"},[_c('filter-group',[_c('div',{staticClass:"filter-item"},[_c('span',{staticClass:"filter-text"},[_vm._v(_vm._s(_vm.$t('common.date')))]),_c('a-date-picker',{staticClass:"filter-input",attrs:{"allowClear":false,"disabledDate":_vm.disabledDate},on:{"change":_vm.selectDate},model:{value:(_vm.filters.date),callback:function ($$v) {_vm.$set(_vm.filters, "date", $$v)},expression:"filters.date"}})],1),_c('div',{staticClass:"filter-item"},[_c('span',{staticClass:"filter-text"},[_vm._v(_vm._s(_vm.$t('common.homeroom')))]),_c('a-cascader',{staticClass:"filter-input",attrs:{"options":_vm.homerooms,"showSearch":{ filter: _vm.filterData },"fieldNames":{
            label: _vm.locale === 'zh' ? 'value' : 'enValue',
            value: 'key',
            children: 'subOptions',
          },"placeholder":_vm.$t('common.select')},on:{"change":_vm.onHomeroomChange},model:{value:(_vm.filters.homeroomId),callback:function ($$v) {_vm.$set(_vm.filters, "homeroomId", $$v)},expression:"filters.homeroomId"}})],1),_c('div',{staticClass:"filter-item"},[_c('span',{staticClass:"filter-text"},[_vm._v(_vm._s(_vm.$t('common.advisory')))]),_c('a-select',{staticClass:"filter-input",attrs:{"showSearch":"","allow-clear":"","optionFilterProp":"children","placeholder":_vm.$t('common.select')},on:{"change":function (e) { return (_vm.weekViewer ? _vm.getWeekData() : _vm.getData()); }},model:{value:(_vm.filters.houseGroupId),callback:function ($$v) {_vm.$set(_vm.filters, "houseGroupId", $$v)},expression:"filters.houseGroupId"}},_vm._l((_vm.houses),function(house){return _c('a-select-opt-group',{key:house.houseId,attrs:{"label":house.houseName}},_vm._l((house.houseGroups),function(group){return _c('a-select-option',{key:group.houseGroupId},[_vm._v(_vm._s(house.houseName + group.name))])}),1)}),1)],1)])],1),_c('div',{staticClass:"button-container"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.changeViewer}},[_c('span',[_vm._v(_vm._s(_vm.$t(("attendance." + (_vm.weekViewer ? 'day' : 'week') + "Viewer"))))])])],1),_c('a-table',{attrs:{"columns":_vm.columns,"dataSource":_vm.data,"pagination":_vm.pagination,"loading":_vm.loading,"rowKey":function (record) { return record.studentId; },"scroll":_vm.weekViewer
        ? { x: 80 * (_vm.dynamicHeader || []).length + 120 * 2 + 240, y: _vm.windowH - 216 }
        : { y: _vm.windowH - 216 }},on:{"change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"gender",fn:function(text){return [_c('span',[_vm._v(_vm._s(text ? _vm.$t(("common.gender." + text)) : ''))])]}},{key:"name",fn:function(record){return [_c('flex-tooltip',{attrs:{"value":record.studentName,"imgUrl":record.studentAvatar,"showImg":"","canClick":_vm.operationAuths.includes('2008')},on:{"click":function($event){return _vm.viewDetail(
            record.studentId,
            record.studentName,
            record.campusType,
            record.attendanceType
          )}}})]}},{key:"currentRemark",fn:function(record){return [_c('a-tooltip',{attrs:{"title":record.comment,"getPopupContainer":function () { return _vm.$document.body; },"placement":"topRight"}},[_c('a-button',{attrs:{"type":"link","disabled":_vm.attendanceDisabled},on:{"click":function($event){return _vm.addRemark(record, 'status')}}},[_c('a-icon',{style:({ color: record.comment ? '#26b889' : 'rgba(0, 0, 0, 0.12)' }),attrs:{"type":"message"}})],1)],1)]}},{key:"currentAttendance",fn:function(record){return [_c('div',{staticClass:"flex-style align-center"},[_c('a-tooltip',{style:({
            visibility: record.leaveInfo && record.leaveInfo.length ? 'unset' : 'hidden',
          }),attrs:{"overlayStyle":{ maxWidth: '320px' }},scopedSlots:_vm._u([(record.leaveInfo && record.leaveInfo.length)?{key:"title",fn:function(){return [_vm._l((record.leaveInfo),function(item){return [_c('div',{key:("time-" + (item.startTime))},[_c('span',[_vm._v(_vm._s(_vm.$t('leaveApproval.leaveTime'))+"：")]),_c('span',[_vm._v(_vm._s(_vm._f("doubleMoment")([item.startTime, item.endTime],'YYYY.MM.DD HH:mm')))])]),_c('div',{key:("reason-" + (item.startTime))},[_c('span',[_vm._v(_vm._s(_vm.$t('leaveApproval.leaveReason'))+"：")]),_c('span',[_vm._v(_vm._s(item.reason))])])]})]},proxy:true}:null],null,true)},[_c('svg-icon',{attrs:{"icon":"takeLeave","className":"take-leave-svg"}})],1),_c('a-dropdown',{attrs:{"trigger":['click'],"disabled":_vm.attendanceDisabled || !record.editFlag}},[_c('a-menu',{attrs:{"slot":"overlay"},on:{"click":function (ref) {
                  var key = ref.key;

                  return _vm.setAttendance(key, record.studentId, record.comment);
          }},slot:"overlay"},_vm._l((_vm.statusList),function(item){return _c('a-menu-item',{key:item,style:({
                backgroundColor: _vm.calcBg(item),
                color: item !== 'noRecords' ? '#fff' : 'rgba(0, 0, 0, 0.65)',
              })},[_vm._v(_vm._s(_vm.$t('attendance.statusList.' + item)))])}),1),_c('a-tooltip',{attrs:{"title":record.status ? _vm.$t('attendance.statusList.' + record.status) : _vm.$t('common.select'),"getPopupContainer":function () { return _vm.$document.body; }}},[_c('a-button',{staticClass:"attendance-button",style:({ backgroundColor: _vm.calcBg(record.status) }),attrs:{"disabled":_vm.attendanceDisabled || !record.editFlag}},[_c('span',{staticClass:"ellipsis",style:({ color: record.status !== 'noRecords' ? '#fff' : 'rgba(0, 0, 0, 0.25)' })},[_vm._v(_vm._s(record.status ? _vm.$t('attendance.statusList.' + record.status) : _vm.$t('common.select')))]),_c('a-icon',{attrs:{"type":"down"}})],1)],1)],1)],1)]}},{key:"dynamicColumns",fn:function(text, record, index, col){return [_c('a-dropdown',{attrs:{"getPopupContainer":function () { return _vm.$document.body; },"trigger":['click'],"disabled":!_vm.operationAuths.includes('2007') || _vm.moment(col.date) < _vm.currentSchoolYear.startTime}},[_c('div',{staticStyle:{"width":"100%"}},[_vm._v(_vm._s(_vm.calcSymbol(text)))]),_c('a-menu',{attrs:{"slot":"overlay"},on:{"click":function (ref) {
                      var key = ref.key;

                      return _vm.setWeekAttendance(key, record, col.key);
              }},slot:"overlay"},_vm._l((_vm.statusList),function(item){return _c('a-menu-item',{key:item,style:({
              backgroundColor: _vm.calcBg(item),
              color: item !== 'noRecords' ? '#fff' : 'rgba(0, 0, 0, 0.65)',
            })},[_vm._v(_vm._s(_vm.$t('attendance.statusList.' + item)))])}),1)],1)]}},{key:"fixedName",fn:function(record){return [_c('div',{staticStyle:{"max-width":"196px"}},[_c('flex-tooltip',{attrs:{"value":record.studentName,"imgUrl":record.avatarUrl,"showImg":"","canClick":_vm.operationAuths.includes('2008')},on:{"click":function($event){return _vm.viewDetail(
              record.studentId,
              record.studentName,
              record.campusType,
              record.attendanceType
            )}}})],1)]}},{key:"fixedGender",fn:function(text){return [_c('span',[_vm._v(_vm._s(text ? _vm.$t(("common.gender." + text)) : ''))])]}},{key:"commonFix",fn:function(text){return [_c('div',{staticStyle:{"max-width":"96px"}},[_c('flex-tooltip',{attrs:{"value":text}})],1)]}}])},[_c('template',{slot:"attendance"},[_c('span',{staticClass:"title-tag"},[_vm._v(_vm._s(_vm.$t('attendance.attendance')))]),(_vm.operationAuths.includes('2007'))?_c('a-popconfirm',{attrs:{"title":_vm.$t('attendance.batchTip'),"getPopupContainer":function () { return _vm.$document.body; },"disabled":_vm.allPunctualDisabled,"loading":_vm.batchCILoading},on:{"confirm":function($event){return _vm.allNormal()}}},[_c('a-button',{attrs:{"type":"primary","size":"small","disabled":_vm.attendanceDisabled}},[_vm._v(_vm._s(_vm.$t('attendance.onTime')))])],1):_vm._e()],1)],2),_c('add-remark',{attrs:{"record":_vm.record,"date":_vm.filters.date,"addType":"class"},on:{"modify":_vm.refresh},model:{value:(_vm.remarkVisible),callback:function ($$v) {_vm.remarkVisible=$$v},expression:"remarkVisible"}}),(_vm.studentCampus !== '1230')?_c('ele-student-detail',{attrs:{"studentId":_vm.visStudent.studentId,"studentName":_vm.visStudent.studentName,"isDay":true},on:{"echoModify":function (val) { return (_vm.detailModify = val); }},model:{value:(_vm.detailVisible),callback:function ($$v) {_vm.detailVisible=$$v},expression:"detailVisible"}}):_c('pre-student-detail',{attrs:{"studentId":_vm.visStudent.studentId,"studentName":_vm.visStudent.studentName},on:{"echoModify":function (val) { return (_vm.detailModify = val); }},model:{value:(_vm.detailVisible),callback:function ($$v) {_vm.detailVisible=$$v},expression:"detailVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }